export async function getAnswer(prompt) {
  const response = await fetch("https://api.gptfree.com/v1/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      prompt,
    }),
  });

  return response.json();
}
