import React, { useMemo } from "react";
import "./Chat.css";
import ReactMarkdown from "react-markdown";
import logo from "../logo192.png";

export function ChatItem(props) {
  const wrapperStyles = useMemo(() => {
    if (!props.isUser) {
      return {
        backgroundColor: "var(--color-grey-dark)",
        color: "var(--color-text-gray)",
        borderTop: "1px solid #333",
        borderBottom: "1px solid rgba(32,33,35,.5)",
      };
    }
    return {};
  }, [props.isUser]);

  const image = useMemo(() => {
    if (props.isUser) {
      return "https://placekitten.com/50/50";
    }

    return logo;
  }, [props.isUser]);

  return (
    <div className="ChatItemWrap" style={wrapperStyles}>
      <div className="ChatItem">
        <img src={image} alt="User" className="ChatItemImage" />
        <ReactMarkdown className="ChatItemContent">
          {props.content}
        </ReactMarkdown>
      </div>
    </div>
  );
}
