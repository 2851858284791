import React, { useCallback, useEffect } from "react";
import "./Chat.css";
import { ChatItem } from "./ChatItem";
import { ChatInput } from "./ChatInput";
import { getAnswer } from "../API/ModelAPI";
/*
This is a clone of the chat.openai.com interface
*/

function buildQuery(messages) {
  return messages
    .map((message) => {
      if (message.isUser) {
        return `Q: ${message.content}`;
      } else {
        return `A: ${message.content}`;
      }
    })
    .join("\n");
}

export function Chat() {
  const [inputEnabled, setInputEnabled] = React.useState(true);
  const chatOutput = React.useRef(null);
  const [messages, setMessages] = React.useState([
    {
      content: "Hello there, how can I help you?\n",
      isUser: false,
    },
  ]);

  const handleSubmit = useCallback((value) => {
    setInputEnabled(false);
    setMessages((messages) => [
      ...messages,
      {
        content: value,
        isUser: true,
      },
    ]);
  }, []);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && !lastMessage.isUser) {
      return;
    }

    // const query = buildQuery(messages);
    const query = lastMessage.content;
    let cancel = false;
    getAnswer(query).then((response) => {
      if (cancel) {
        return;
      }
      setMessages((messages) => [
        ...messages,
        {
          content: response.answer,
          isUser: false,
        },
      ]);
      setInputEnabled(true);
    });

    return () => {
      cancel = true;
    };
  }, [messages]);

  useEffect(() => {
    chatOutput.current.scrollTop = chatOutput.current.scrollHeight;
  }, [messages]);

  return (
    <div className="Chat">
      <div className="ChatOutput" ref={chatOutput}>
        {messages.map((message, index) => (
          <ChatItem
            key={index}
            content={message.content}
            isUser={message.isUser}
          />
        ))}
      </div>
      <ChatInput onSubmit={handleSubmit} disabled={!inputEnabled} />
    </div>
  );
}
