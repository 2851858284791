import React, { useCallback, useMemo } from "react";
import "./Chat.css";

export function ChatInput({ onSubmit, disabled }) {
  const [input, setInput] = React.useState("");

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const value = e.target.value.trim();

      if (!value) return;

      if (onSubmit) {
        onSubmit(value);
      }
      setInput("");
    },
    [onSubmit]
  );

  const handleInput = useCallback((e) => {
    const element = e.target;
    /* automatically resize height */
    element.style.height = 0;
    element.style.height = element.scrollHeight - 32 + "px";
  }, []);

  const handleChange = useCallback(
    (e) => {
      setInput(e.target.value);
      handleInput(e);
    },
    [setInput, handleInput]
  );

  const handleKeyDown = useCallback(
    (e) => {
      /* ignore if the user is holding shift */
      if (e.shiftKey) return;
      if (e.key === "Enter") {
        if (disabled) {
          e.preventDefault();
          return;
        }
        handleSubmit(e);
        e.target.style.height = "auto";
      }
    },
    [handleSubmit, disabled]
  );

  return (
    <div className="ChatInputWrap">
      <textarea
        type="text"
        className="ChatInput"
        placeholder="Type your message here"
        value={input}
        onChange={handleChange}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        rows={1}
        /* autofocus */
        autoFocus
        /* disable spellcheck */
        spellCheck={false}
        /* disable auto complete */
        autoComplete="off"
      />
      <div className="ChatInputInformation">
        Are you sick of AI telling you want you want to hear? &nbsp;
        <a href="chat.gptfree.com">GPTFree</a> will do the opposite - for free!
        <br />
        This is not ChatGPT, to use ChatGPT, please visit{" "}
        <a href="https://chat.openai.com/chat">here</a>.
      </div>
    </div>
  );
}
